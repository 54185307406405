<template lang="pug">
    section#ribbonSection
        .wrapper
            Editor(:contents="editor" v-if="editor")
            .image
                img(:src="image" v-if="contents.content.image")
            Link(:contents='contents.content').link {{contents.content.name}}
</template>

<script>
import { props } from '@/mixins/component'
import Editor from '@sections/Pagina/Editor/Editor'
import Link from '@components/Link/Link'

export default {
    name: "section-alimentos-coamo",
    props,
    components: {
        Editor,
        Link
    },
    computed: {
        editor() {
            return {content: this.contents.content.editor}
        },
        image() {
            return `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${this.contents.content.image}`
        },
    },
}
</script>

<style lang="stylus" scoped src="./RibbonSection.styl"></style>